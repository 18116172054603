<template>
	<ReservationList mode="private" />
</template>

<script>
import ReservationList from '../components/ReservationList'
export default {
	name: 'Reservation',
	components: {
		ReservationList
	},
}
</script>
