<template>
	<v-main>
		<v-container>
			<v-card class="mx-auto mt-5 pa-1" max-width="600" color="singin_card" :loading="loading">
				<v-card-title>{{ title }}</v-card-title>
				<v-card-subtitle>パスワード変更</v-card-subtitle>
				<v-form ref="form">
					<v-card-text>
						<v-text-field v-model="username" label="社員番号" required readonly prepend-icon="mdi-account" />
						<v-text-field v-model="password" label="現在のパスワード" required :rules="[rules.required]" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" />
						<v-text-field v-model="newpassword" label="新しいパスワード" required :rules="[rules.required]" prepend-icon="mdi-lock" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" />
						<v-text-field v-model="confirmpassword" label="新しいパスワードの確認" required :rules="[rules.required]" prepend-icon="mdi-lock" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'" @click:append="show3 = !show3" />

					</v-card-text>
					<v-alert dense icon="mdi-account" type="error" v-if="message">{{ message }}</v-alert>
					<v-card-actions>
						<v-btn :disabled="loading" @click="submit" class="mx-auto">変更</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import Axios from 'axios'
export default {
	data: () => ({
		loading: false,
		title: '北上エレメック',
		numeric: true,
		show1: false,
        show2: false,
        show3: false,
		username: '',
		password: '',
        newpassword:'',
        confirmpassword:'',
		rules: {
			required: value => !!value || '必須の項目.',
			min: v => v.length >= 8 || '８文字以上',
		},
		message: ''
	}),
	methods: {
		submit: function (event) {
			if(this.newpassword !== this.confirmpassword){
				this.message = '新しいパスワードと新しいパスワードの確認があいません'
				return;
			}
			this.loading = true;
			this.message = '';
			let data = {
				grant_type: 'password',
				username: this.username,
				password: this.password,
                newpassword: this.newpassword
			};
			Axios
				.post('/api/me/changepassword', data)
				.then(response => {
                    if(response.data.status == 'success'){
                        sessionStorage.removeItem('isSignin')
                        sessionStorage.removeItem('username', response.data.username)
                        sessionStorage.removeItem('display_name', response.data.display_name)
                        setTimeout( () => this.$router.push({ path: '/signin'}), 5000);
                    }
                    this.message = response.data.message;
					//this.$router.push('/singin')
				})
				.catch(error => {
					console.debug(error)
					if (error.response.status == 0) {
						this.message = "サーバーに接続できませんでした（ネットワークエラー）"
					} else {
						this.message = error.response.data.error_description ?? error.message;
					}
				})
				.finally(() => {
					this.loading = false
				});
		}
	},
	mounted() {
		let username = sessionStorage.getItem('username')
		if (username != null) {
			this.username = username;
		}else{
            this.message = 'エラー'
        }
	}
}
</script>

