<template>
	<v-card color="bg_card">
		<v-card-title>リンク</v-card-title>
		<v-card-text>
			<v-data-table dense :loading="loading" :headers="headers" :items="items" :options="options">
				<template #item.url="{ item }">
					<a target="_blank" :href="item.url">
						{{ item.url }}
					</a>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { vdatatableMixin } from '@/mixins/vdatatableMixin.js';
export default {
	mixins: [vdatatableMixin],
	name: 'LinkList',
	data: () => ({
		dataUrl: '/api/link/',
		loading: false,
		headers: [
			{ text: '名称', align: 'left', sortable: true, value: 'hyoujimei', divider: true },
			{ text: 'アドレス', align: 'left', sortable: true, value: 'url', divider: true },
		],
		items: [
		],
		'item-key': '_id',
		options: {
			itemsPerPage: 100,
		}
	}),
	methods: {
		openlink(event, value) {
			if (value.item.url !== '') {
				console.log(value.item.url);
				window.open(value.item.url);
			}
		}
	},
	mounted() {
		this.reload();
	}
};
</script>
