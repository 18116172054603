<template>
	<AddressbookList />
</template>

<script>
import AddressbookList from '../components/AddressbookList'

export default {
	name: 'Addressbook',
	components: {
		AddressbookList
	},
}
</script>
