import Vue from 'vue'
import VueRouter from 'vue-router'
import TopView from '@/views/TopView.vue'
import AddressbookView from '@/views/AddressbookView.vue'
import LinkView from '@/views/LinkView.vue'
import CalendarView from '@/views/CalendarView.vue'
import InfoView from '@/views/InfoView.vue'
import ReservationView from '@/views/ReservationView.vue'
import ApplicationView from '@/views/ApplicationView.vue'
import ApproveView from '@/views/ApproveView.vue'
import AdminView from '@/views/AdminView.vue'
import SigninView from '@/views/SigninView.vue'
import SignoutView from '@/views/SignoutView.vue'
import SingchangepasswordView from '@/views/SingchangepasswordView.vue'
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'
import SimpleLayout from '@/components/layouts/SimpleLayout.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: DashboardLayout,
    props: { title: '北上エレメック社内ホームページ' },
    children: [
      {
        path: 'top',
        name: 'top',
        component: TopView
      }, {
        path: 'addressbook',
        name: 'addressbook',
        component: AddressbookView
      }, {
        path: 'link',
        name: 'link',
        component: LinkView
      }, {
        path: 'calendar',
        name: 'calendar',
        component: CalendarView
      },{
        path: 'info',
        name: 'info',
        component: InfoView
      }, {
        path: 'reservation',
        name: 'reservation',
        component: ReservationView
      }, {
        path: 'application',
        name: 'application',
        component: ApplicationView
      }, {
        path: 'approve',
        name: 'approve',
        component: ApproveView
      }, {
        path: 'admin',
        name: 'admin',
        component: AdminView
      }
    ]
  }, {
    component: SimpleLayout,
    path: '',
    children: [
      {
        path: '/signin',
        name: 'signin',
        component: SigninView
      }]
  }, {
    component: SimpleLayout,
    path: '',
    children: [
      {
        path: '/signout',
        name: 'signout',
        component: SignoutView
      }]
    }, {
      component: SimpleLayout,
      path: '',
      children: [
        {
          path: '/singchangepassword',
          name: 'singchangepassword',
          component: SingchangepasswordView
        }]
    },  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
