<template>
	<CalendarList />
</template>

<script>
import CalendarList from '../components/CalendarList'
export default {
	components: {
		CalendarList
	},
}
</script>
