<template>
	<v-card color="bg_card">
		<v-card-title>グループアドレス帳</v-card-title>
		<v-card-subtitle>KE：本社工場 KEM：南工場 SD：袖ヶ沢工場 KS：㈱北上精密 NS：㈱NSテクノサービス</v-card-subtitle>
		<v-card-text>
			<v-data-table dense :loading="loading" :headers="headers" :items="items"> </v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
import { vdatatableMixin } from '@/mixins/vdatatableMixin.js';
export default {
	mixins: [vdatatableMixin],
	name: 'AddressbookList',
	data: () => ({
		dataUrl: '/api/addressbook/',
		loading: false,
		headers: [
			{ text: '氏名', align: 'left', sortable: true, value: 'shimei', divider: true, width: 120 },
			{ text: '部門', align: 'left', sortable: true, value: 'bumon', divider: true,width: 60 },
			{ text: '役職', align: 'left', sortable: true, value: 'yakushoku', divider: true,width: 150 },
			{ text: '電話番号', align: 'left', sortable: true, value: 'denwa1', divider: true,width: 90 },
			{ text: '携帯電話', align: 'left', sortable: true, value: 'denwa2', divider: true,width: 90 },
			{ text: '内線', align: 'left', sortable: true, value: 'denwa3', divider: true,width: 60},
			{ text: 'メールアドレス', align: 'left', sortable: true, value: 'mailaddress', divider: true,width: 220 },
		],
		items: [
		],
	}),
	methods: {
	},
	mounted() {
		this.reload();
	}
};
</script>
