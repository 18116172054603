<template>
	<div>
		<InfoList />
		<ReservationList mode="public" cat="room" />
		<ReservationList mode="public" cat="car" />
		<ApplicationList mode="public" cat="dayoff" />
		<ApplicationList mode="public" cat="bt" />
		<CalendarList />
	</div>
</template>

<script>
import InfoList from "@/components/InfoList";
import ReservationList from "@/components/ReservationList";
import ApplicationList from "@/components/ApplicationList";
import CalendarList from "@/components/CalendarList";

export default {
	name: "Top",
	components: {
		InfoList,
		ReservationList,
		ApplicationList,
		CalendarList,
	},
};
</script>
