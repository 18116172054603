import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors'
import ja from 'vuetify/lib/locale/ja.js'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { ja },
        current: 'ja',
    },
   
    theme: {
        dark: false,
        themes: {
            light: {
                // primary: colors.orange.base,
                // secondary: colors.yellow.base,
                // accent: colors.indigo.base,
                // error: colors.red.base,
                // warning: colors.pink.base,
                // info: colors.lime.base,
                // success: colors.lightBlue.base,
                bg_appbar:colors.orange.lighten3,
                bg_navi:colors.orange.lighten5,
                bg_card:colors.orange.lighten4,
                singin_card: colors.orange.lighten1
            },
            dark:{
                // primary: colors.red.base,
            }
        }
    }
});
