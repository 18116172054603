<template>
	<v-card color="bg_card">
		<v-card-text>
			<v-toolbar flat dense>
				<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> 今日 </v-btn>
				<v-btn fab text small color="grey darken-2" @click="prev">
					<v-icon small> mdi-chevron-left </v-icon>
				</v-btn>
				<v-btn fab text small color="grey darken-2" @click="next">
					<v-icon small> mdi-chevron-right </v-icon>
				</v-btn>
				<v-toolbar-title v-if="$refs.calendar">
					{{ $refs.calendar.title }}
				</v-toolbar-title>
			</v-toolbar>
			<v-calendar ref="calendar" v-model="focus" color="primary" type="month" category-show-all :events="events" @change="fetchEvents">
			</v-calendar>
		</v-card-text>
		<v-snackbar v-model="snackbar" color="error">
			{{ message }}
			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import Axios from 'axios'
export default {
	data: () => ({
		dataUrl: '/api/calendar/',
		focus: '',
		events: [],
		message: '',
		snackbar: false
	}),
	mounted() {
		this.$refs.calendar.checkChange()
	},
	methods: {
		getEventColor(event) {
			return event.color
		},
		setToday() {
			this.focus = ''
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		fetchEvents({ start, end }) {
			console.log(start.date, end.date);
			this.editLoading = true
			this.message = ''
			const config = {
				url: `${this.dataUrl}event/`,
				method: 'get',
				params: { start: start.date, end: end.date }
			};
			Axios
				.request(config)
				.then(response => {
					if (Array.isArray(response.data) && response.data.length > 0) {
						//response.data.forEach(el=>this.events.push(el))
						this.events = response.data
						setTimeout(()=>document.querySelectorAll("[data-date]").forEach(el=>el.parentElement.classList.add('holiday'))
							,1000
						)
					}
				}).catch(error => {
					console.debug(error)
					if (!error.response) {
						this.message = "致命的なエラーです"
					} else if (error.response.status == 0) {
						this.message = "サーバーに接続できませんでした（ネットワークエラー）"
					} else if (error.response.status >= 500) {
						this.message = `サーバーエラーが発生しました。(${error.response.status})`;
					} else if (error.response.status == 401) {
						this.message = `再ログインが必要です。(${error.response.status})`;
					} else {
						this.message = `データが取得できませんした。(${error.response.status})`;
					}
					this.snackbar = true
				})
				.finally(() => {
					this.editLoading = false
				});
		},
	},


}
</script>
<style>
.v-calendar-weekly__day:first-child {
	background-color: pink;
}
.v-calendar-weekly__day:last-child {
	background-color: lightskyblue;
}

.holiday {
	background-color: pink;
}

</style>