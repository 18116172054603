<template>
	<v-main>
		<v-container>
			<v-card class="mx-auto mt-5 pa-1" max-width="600" color="singin_card" :loading="loading">
				<v-card-title>{{ title }}</v-card-title>
				<v-card-subtitle>サインイン</v-card-subtitle>
				<v-form ref="form">
					<v-card-text>
						<v-text-field tabindex="1" v-model="username" label="社員番号" required :rules="[rules.required]" prepend-icon="mdi-account" :append-icon="numeric ? 'mdi-numeric' : 'mdi-alphabetical'" :type="numeric ? 'number' : 'text'" @click:append="numeric = !numeric" />
						<v-text-field tabindex="2" v-model="password" label="パスワード" required :rules="[rules.required]" prepend-icon="mdi-lock" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" />
					</v-card-text>
					<v-alert dense icon="mdi-account" type="error" v-if="message">{{ message }}</v-alert>
					<v-card-actions>
						<v-btn tabindex="3" :disabled="loading" @click="submit" class="mx-auto">サインイン</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import Axios from 'axios'
export default {
	data: () => ({
		loading: false,
		title: '北上エレメック社内ホームページ',
		numeric: true,
		show: false,
		username: '',
		password: '',
		rules: {
			required: value => !!value || '必須の項目.',
			min: v => v.length >= 8 || '８文字以上',
		},
		message: ''
	}),
	methods: {
		submit: function (event) {
			if (!this.$refs.form.validate()) return;
			this.loading = true;
			this.message = '';
			sessionStorage.removeItem('isSignin')
			sessionStorage.removeItem('username')
			sessionStorage.removeItem('display_name')
			sessionStorage.removeItem('admin')
			let data = {
				grant_type: 'password',
				username: this.username,
				password: this.password
			};
			Axios
				.post('/api/signin/', data)
				.then(response => {
					sessionStorage.setItem('isSignin', 'TRUE')
					sessionStorage.setItem('username', response.data.username)
					sessionStorage.setItem('display_name', response.data.display_name)
					sessionStorage.setItem('admin', response.data.admin == 1 ? true : false)
					this.$router.push('/top')
				})
				.catch(error => {
					console.debug(error)
					if (error.response.status == 0) {
						this.message = "サーバーに接続できませんでした（ネットワークエラー）"
					} else {
						this.message = error.response.data.error_description ?? error.message;
					}
				})
				.finally(() => {
					this.loading = false
				});
		}
	},
	mounted() {
		let username = sessionStorage.getItem('username')
		if (username != null) {
			this.username = username;
		}
	}
}
</script>

