<template>
	<v-card color="bg_card">
		<v-card-title>{{ title }}</v-card-title>
		<v-card-text>
			<v-data-table dense :loading="loading" :headers="displayHeaders" :items="items">
				<template v-slot:top v-if="mode == 'private'">
					<v-toolbar dense flat>
						<v-btn color="secondry" small icon :disabled="editLoading" v-on:click="reload()">
							<v-icon>mdi-reload</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" persistent max-width="600px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" small icon v-on="on" v-bind="attrs">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							<v-card :loading="editLoading">
								<v-card-title>社有車・会議室予約登録</v-card-title>
								<v-card-text>
									<v-container>
										<v-form ref="form">
											<v-row dense>
												<v-col cols="12" sm="12" md="6">
													<v-text-field v-model="editedItem.ji" label="開始" type="datetime-local" :rules="[rules.required, rules.datetime]"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12" md="6">
													<v-text-field v-model="editedItem.shi" label="終了" type="datetime-local" :rules="[rules.required, rules.datetime]"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12">
													<v-select v-model="editedItem.shurui" label="種類" :items="shuruiItems" :rules="[rules.required]"></v-select>
												</v-col>
												<v-col cols="12" sm="12">
													<v-text-field v-model="editedItem.naiyou" label="内容"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12">
													<v-text-field v-model="editedItem.shussekisha" label="対応者/来客者"></v-text-field>
												</v-col>
											</v-row>
										</v-form>
										<v-alert :value="!!message" type="error">{{ message }}</v-alert>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('削除')">削除</v-btn>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="close">キャンセル</v-btn>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('保存')">保存</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:item.ji="{ item }">
					{{ formatDatetime(item.ji) }}
				</template>
				<template v-slot:item.shi="{ item }">
					{{ formatDatetime(item.shi) }}
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { vdatatableMixin } from '@/mixins/vdatatableMixin.js';
import Axios from 'axios'
export default {
	mixins: [vdatatableMixin],
	name: 'ReservationList',
	data: () => ({
		dataUrl: '/api/reservation/',
		headers: [
			{ text: '開始', align: 'left', sortable: true, value: 'ji', divider: true, width: 150 },
			{ text: '終了', align: 'left', sortable: true, value: 'shi', divider: true, width: 150 },
			{ text: '種類', align: 'left', sortable: true, value: 'shurui', divider: true, width: 200 },
			{ text: '内容', align: 'left', sortable: true, value: 'naiyou', divider: true },
			{ text: '対応者/来客者', align: 'left', sortable: true, value: 'shussekisha', divider: true ,width: 280 },
			{ text: '予約者', align: 'left', sortable: true, value: '_display_name', divider: true, width: 180 },
			{ text: '編集', align: 'left', sortable: false, value: 'actions', divider: true, width: 60 },
		],
		items: [
		],
		shuruiItems: [],
		editLoading: false,
		editedItem: {
			id: '', ji: '', shi: '', shurui: '', naiyou: '', kubun: '', _display_name: ''
		},
		defaultItem: {
			id: '00000000-0000-0000-0000-000000000000', ji: '', shi: '', shurui: '', naiyou: '', kubun: '', _display_name: ''
		},
		titles: { room: '会議室予約', car: '社有車予約' }
	}),
	computed: {
		title: function () {
			return this.titles[this.cat] ?? '社有車・会議室予約登録'
		},
		displayHeaders: function () {
			//表示する項目を絞る
			let zzz = ['ji', 'shi', 'shurui', 'naiyou', 'shussekisha', '_display_name'];
			if (this.mode == 'private') {
				zzz = ['ji', 'shi', 'shurui', 'naiyou', 'shussekisha', 'actions']
			}
			return this.headers.filter(e => zzz.includes(e.value))
		}
	},
	methods: {
		// validate(item) {
		// 	let result = false;
		// },
		commit(mode) {
			//mode は "削除"か"保存"か
			if (this.editedIndex == -1) { this.editedItem.id = this.defaultItem.id }
			const id = this.editedItem.id;
			const config = {
				url: `${this.dataUrl}${id}`,
				method: 'put',
				data: this.editedItem
			};
			if (mode == '削除') {
				config.method = 'delete'
			}
			this.editedItem.kubun = this.shuruiItems.find(e => e.text == this.editedItem.shurui)?.cat ?? '不明';
			this.message = ""
			if ((mode == '削除' && this.editedIndex != -1) || this.$refs.form.validate()) {
				this.editLoading = true;
				Axios
					.request(config)
					.then(response => {
						if (response.data.status == 'success') {
							if (this.editedIndex != -1) {
								if (mode == '削除') {
									this.items.splice(this.editedIndex, 1)
								} else {
									Object.assign(this.items[this.editedIndex], response.data.item)
								}
							} else {
								this.items.push(response.data.item)
							}
							this.close()
						} else if (response.data.status == 'error') {
							console.debug(response.data.message)
							this.message = response.data.message;
						} else {
							this.message = `${mode}時にエラーが発生しました`
						}
					})
					.catch(error => {
						console.debug(error)
						if (error.response) {
							if (error.response.status == 0) {
								this.message = "サーバーに接続できませんでした（ネットワークエラー）"
							} else if (error.response.status >= 500) {
								this.message = `サーバーエラーが発生しました。(${error.response.status})`;
							} else if (error.response.status == 401) {
								this.message = `再ログインが必要です。(${error.response.status})`;
							} else {
								this.message = `登録できませんでした。(${error.response.status})`;
							}
						} else {
							this.message = "致命的なエラーです"
						}
					})
					.finally(() => {
						this.editLoading = false
					});
			}
		},
		getshuruiItems() {
			Axios
				.get('/api/resource/b3b6bc56-62d8-499a-a064-f5ba90bd18fa')
				.then(response => {
					this.shuruiItems = response.data;
				})
		},
	},
	mounted() {
		this.getshuruiItems();
	}
};
</script>
