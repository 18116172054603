<template>
	<v-card color="bg_card">
		<v-card-title>お知らせ</v-card-title>
		<v-card-text>
			<v-data-table dense :loading="loading" :headers="displayHeaders" :items="items">
				<template v-slot:top v-if="mode == 'private'">
					<v-toolbar dense flat>
						<v-btn color="secondry" small icon :disabled="editLoading" v-on:click="reload()">
							<v-icon>mdi-reload</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" persistent max-width="600px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" small icon v-on="on" v-bind="attrs">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							<v-card :loading="editLoading">
								<v-card-title>お知らせ登録</v-card-title>
								<v-card-text>
									<v-container>
										<v-form ref="form">
											<v-row dense>
												<v-col cols="12" sm="12" md="6">
													<v-text-field v-model="editedItem.ji" label="公開開始日" type="datetime-local" :rules="[rules.required, rules.datetime]"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12" md="6">
													<v-text-field v-model="editedItem.shi" label="公開終了日" type="datetime-local" :rules="[rules.required, rules.datetime]"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12">
													<v-text-field v-model="editedItem.hyoudai" label="表題" :rules="[rules.required]"></v-text-field>
												</v-col>
												<v-col cols="12" sm="12">
													<v-textarea v-model="editedItem.naiyou" label="内容"></v-textarea>
												</v-col>
											</v-row>
										</v-form>
										<v-alert :value="!!message" type="error">{{ message }}</v-alert>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('削除')">削除</v-btn>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="close">キャンセル</v-btn>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('保存')">保存</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:top v-else>
					<v-dialog v-model="dialogDetail" persistent max-width="600px">
						<v-card>
							<v-card-title>{{ detailItem.hyoudai }}</v-card-title>
							<v-card-text>
								<div class="text-pre-wrap text--primary">
									{{ detailItem.naiyou }}
								</div>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="dialogDetail = false">閉じる</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</template>
				<template v-slot:item.ji="{ item }">
					{{ formatDatetime(item.ji) }}
				</template>
				<template v-slot:item.shi="{ item }">
					{{ formatDatetime(item.shi) }}
				</template>
				<template v-slot:item.detail="{ item }">
					<v-icon small class="mr-2" @click="showDetail(item)">mdi-monitor</v-icon>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { vdatatableMixin } from '@/mixins/vdatatableMixin.js';
import Axios from 'axios'
export default {
	mixins: [vdatatableMixin],
	name: 'InfoList',
	data: () => ({
		dataUrl: '/api/info/',
		headers: [
			{ text: '表題', align: 'left', sortable: false, value: 'hyoudai', divider: true },
			{ text: '内容', align: 'left', sortable: false, value: 'naiyou', divider: true },
			{ text: '作成者', align: 'left', sortable: true, value: '_display_name', divider: true, width: 180 },
			{ text: '公開開始日', align: 'left', sortable: false, value: 'ji', divider: true, width: 170 },
			{ text: '公開終了日', align: 'left', sortable: false, value: 'shi', divider: true, width: 170 },
			{ text: '詳細', align: 'left', sortable: false, value: 'detail', divider: true, width: 60 },
			{ text: '編集', align: 'left', sortable: false, value: 'actions', divider: true, width: 60 },
		],
		items: [],
		editLoading: false,
		editedItem: {
			id: '', ji: '', shi: '', hyoudai: '', naiyou: '', _display_name: ''
		},
		defaultItem: {
			id: '00000000-0000-0000-0000-000000000000', ji: '', shi: '', hyoudai: '', naiyou: '', _display_name: ''
		},
		detailItem: {
			id: '', ji: '', shi: '', hyoudai: '', naiyou: '', _display_name: ''
		},
		dialogDetail: false
	}),
	computed: {
		displayHeaders: function () {
			//表示する項目を絞る
			let zzz = ['表題', '作成者', '詳細']
			if (this.mode == 'private') {
				zzz = ['表題', '公開開始日', '公開終了日', '編集']
			}
			return this.headers.filter(e => zzz.includes(e.text))
		},
	},
	methods: {
		commit(mode) {
			//mode は "削除"か"保存"か
			if (this.editedIndex == -1) { this.editedItem.id = this.defaultItem.id }
			const id = this.editedItem.id;
			const config = {
				url: `${this.dataUrl}${id}`,
				method: 'put',
				data: this.editedItem
			};
			if (mode == '削除') {
				config.method = 'delete'
			}
			this.message = ""
			if ((mode == '削除' && this.editedIndex != -1) || this.$refs.form.validate()) {
				this.editLoading = true;
				Axios
					.request(config)
					.then(response => {
						if (response.data.status == 'success') {
							if (this.editedIndex != -1) {
								if (mode == '削除') {
									this.items.splice(this.editedIndex, 1)
								} else {
									Object.assign(this.items[this.editedIndex], response.data.item)
								}
							} else {
								this.items.push(response.data.item)
							}
							this.close()
						} else if (response.data.status == 'error') {
							console.debug(response.data.message)
							this.message = response.data.message;
						} else {
							this.message = `${mode}時にエラーが発生しました`
						}
					})
					.catch(error => {
						console.debug(error)
						if (error.response) {
							if (error.response.status == 0) {
								this.message = "サーバーに接続できませんでした（ネットワークエラー）"
							} else if (error.response.status >= 500) {
								this.message = `サーバーエラーが発生しました。(${error.response.status})`;
							} else if (error.response.status == 401) {
								this.message = `再ログインが必要です。(${error.response.status})`;
							} else {
								this.message = `登録できませんでした。(${error.response.status})`;
							}
						} else {
							this.message = "致命的なエラーです"
						}
					})
					.finally(() => {
						this.editLoading = false
					});
			}
		},
		showDetail(item) {
			this.detailItem = Object.assign({}, item)
			this.dialogDetail = true
		},
	}, mounted() {
		//this.reload();
	}
}
</script>
