<template>
	<ApproveList mode="private" />
</template>

<script>
import ApproveList from '../components/ApproveList'
export default {
	name: 'ApproveView',
	components: {
		ApproveList
	},
}
</script>
