<template>
	<v-main>
		<v-container>
			<v-card class="mx-auto mt-5 pa-1" max-width="600" color="singin_card" :loading="loading">
				<v-card-title>{{ title }}</v-card-title>
				<v-card-subtitle>サインアウト</v-card-subtitle>
				<v-alert dense icon="mdi-account" type="error" v-if="message">{{ message }}</v-alert>
				<v-card-actions>
					<v-btn outlined rounded @click="$router.push('/signin')" class="mx-auto"> 再ログイン </v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import Axios from 'axios'
export default {
	data: () => ({
		loading: false,
		title: '北上エレメック',
		message: ''
	}),
	methods: {
		signout() {
			this.loading = true
			sessionStorage.removeItem('isSignin');
			sessionStorage.removeItem('username');
			sessionStorage.removeItem('display_name');
			sessionStorage.removeItem('admin');
			Axios
				.get('/api/signout/')
				.then(response => {
					this.message = response.data.message ?? 'サイン'
				})
				.catch(error => {
					if (error.response.status == 0) {
						this.message = "サーバーに接続できませんでした（ネットワークエラー）"
					} else {
						this.message = error.response.data.error_description ?? error.message;
					}
				})
				.finally(() => {
					this.loading = false
				});
		}
	},
	mounted() {
		this.signout()
	}
}
</script>

