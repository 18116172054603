<template>
	<v-card color="bg_card">
		<v-card-title>休暇・出張承認</v-card-title>
		<v-card-text>
			<v-data-table dense :loading="loading" :headers="displayHeaders" :items="items">
				<template v-slot:top v-if="mode == 'private'">
					<v-toolbar dense flat>
						<v-toolbar-items>
						<v-btn color="secondry" small icon :disabled="editLoading" v-on:click="reload()">
							<v-icon>mdi-reload</v-icon>
						</v-btn>
						<v-switch class="ma-3" true-value="all" false-value="" v-model="range" :label="rangeLabel" :disabled="editLoading"/>
						</v-toolbar-items>
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" persistent max-width="600px">
							<v-card :loading="editLoading">
								<v-card-title>休暇・出張申請承認</v-card-title>
								<v-card-text>
									<v-container>
										<v-form ref="form">
											<v-row dense>
												<v-col cols="12" sm="12">
													<v-textarea v-model="editedItem.comment" label="コメント" outlined hint="必要な場合はコメント入力してください"></v-textarea>
												</v-col>
											</v-row>
										</v-form>
										<v-alert :value="!!message" type="error">{{ message }}</v-alert>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="close">キャンセル</v-btn>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('差戻')">差戻</v-btn>
									<v-btn color="blue darken-1" text :disabled="editLoading" @click="commit('承認')">承認</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-dialog v-model="dialogDetail" persistent max-width="600px">
							<v-card>
								<v-card-title>履歴</v-card-title>
								<v-card-text>
									<v-timeline dense>
										<v-timeline-item v-for="(r, i) in editedItem.rireki" :key="i" small>
											<v-card>
												<v-card-title> {{ r.joutai }} @ {{ r.nichiji }} </v-card-title>
												<v-card-subtitle>{{ r.name }}</v-card-subtitle>
												<v-card-text>
													<div class="text-pre-wrap text--primary">
														{{ r.comment }}
													</div>
												</v-card-text>
											</v-card>
										</v-timeline-item>
									</v-timeline>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="dialogDetail = false">閉じる</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:item.ji="{ item }">
					{{ formatDatetime(item.ji) }}
				</template>
				<template v-slot:item.shi="{ item }">
					{{ formatDatetime(item.shi) }}
				</template>
				<template v-slot:item.detail="{ item }">
					<v-icon small class="mr-2" @click="showDetail(item)">mdi-monitor</v-icon>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
import { vdatatableMixin } from '@/mixins/vdatatableMixin.js';
import Axios from 'axios'
export default {
	mixins: [vdatatableMixin],
	name: 'approveList',
	data: () => ({
		dataUrl: '/api/approve/',
		headers: [
			{ text: '開始', align: 'left', sortable: true, value: 'ji', divider: true, width: 170 },
			{ text: '終了', align: 'left', sortable: true, value: 'shi', divider: true, width: 170 },
			{ text: '種類', align: 'left', sortable: true, value: 'shurui', divider: true, width: 170 },
			{ text: '氏名', align: 'left', sortable: true, value: '_display_name', divider: true, width: 180 },
			{ text: '内容', align: 'left', sortable: true, value: 'naiyou', divider: true },
			{ text: '理由', align: 'left', sortable: true, value: 'riyuu', divider: true },
			{ text: '状態', align: 'left', sortable: true, value: 'shounin_joutai', divider: true, width: 80 },
			{ text: '詳細', align: 'left', sortable: false, value: 'detail', divider: true, width: 60 },
			{ text: '承認', align: 'left', sortable: false, value: 'actions', divider: true, width: 60 },
		],
		items: [
		],
		shutuiItems: [],
		editLoading: false,
		editedItem: {
			id: '', comment: '', shounin_joutai: '', rireki: []
		},
		defaultItem: {
			id: '00000000-0000-0000-0000-000000000000', comment: '', shounin_joutai: '未承認', rireki: []
		},
		dialogDetail: false,
		range: ''
	}),
	computed: {
		displayHeaders: function () {
			//表示する項目を絞る
			let zzz = ['開始', '終了', '氏名', '種類', '内容'];
			if (this.mode == 'private') {
				zzz = ['開始', '終了', '氏名', '種類', '内容', '理由', '状態', '詳細', '承認']
			}
			return this.headers.filter(e => zzz.includes(e.text))
		},
		rangeLabel: function(){
			if(this.range == 'all'){
				return '全て表示'
			}else{
				return '未承認のみ表示'
			}
		}
	},
	methods: {
		commit(mode) {
			//mode は "差戻"か"承認"か（削除はない。コード内では使い回しになっているので一部残っている）
			const id = this.editedItem.id;
			if (this.editedIndex == -1) { this.editedItem.id = this.defaultItem.id }
			const config = {
				url: `${this.dataUrl}${id}`,
				method: 'patch',
				data: this.editedItem
			};
			if (mode == '承認' || mode == '差戻') {
				this.editedItem.shounin_joutai = mode;
			}
			this.message = ""
			if ((mode == '削除' && this.editedIndex != -1) || this.$refs.form.validate()) {
				this.editLoading = true;
				Axios
					.request(config)
					.then(response => {
						if (response.data.status == 'success') {
							if (this.editedIndex != -1) {
								if (mode == '削除') {
									this.items.splice(this.editedIndex, 1)
								} else {
									Object.assign(this.items[this.editedIndex], response.data.item)
								}
							} else {
								this.items.push(response.data.item)
							}
							this.close()
						} else if (response.data.status == 'error') {
							console.debug(response.data.message)
							this.message = response.data.message || '不明なエラー';
						} else {
							this.message = `${mode}時にエラーが発生しました`
						}
					})
					.catch(error => {
						console.debug(error)
						if (error.response) {
							if (error.response.status == 0) {
								this.message = "サーバーに接続できませんでした（ネットワークエラー）"
							} else if (error.response.status >= 500) {
								this.message = `サーバーエラーが発生しました。(${error.response.status})`;
							} else if (error.response.status == 401) {
								this.message = `再ログインが必要です。(${error.response.status})`;
							} else {
								this.message = `登録できませんでした。(${error.response.status})`;
							}
						} else {
							this.message = "致命的なエラーです"
						}
					})
					.finally(() => {
						this.editLoading = false
					});
			}
		},
		showDetail(item) {
			//this.detailItem = Object.assign({}, item)
			this.dialogDetail = true
			this.getRireki(item.id)
		},
		getRireki(id) {
			const url = `${this.dataUrl}rireki/${id}`
			Axios
				.get(url)
				.then(response => {
					if (response.data.status === 'success' &&
						typeof (response.data.item.rireki) === 'string') {
						this.editedItem.rireki = JSON.parse(response.data.item.rireki);
					}
					console.debug(this.editedItem.rireki)
				})
		},
		getShutuiItems() {
			// 00902359-332e-49b3-8429-39f21beefdbaはデータベース上のID
			Axios
				.get('/api/resource/00902359-332e-49b3-8429-39f21beefdba')
				.then(response => {
					this.shutuiItems = response.data;
					//console.debug(response.data)
				})
		},
	},
	mounted() {
		this.reload({ params: { mode: this.mode ?? 'public' } });
	}
};
</script>
