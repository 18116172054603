// ナンチャラListコンポーネントで使う共通部分を集めている
// コンポーネント側では定義していない部分があるので、このミックスインに依存している部分がある
// 

import Axios from 'axios';
export const vdatatableMixin = {
	props: [
		'mode',
		'cat'
	],
	data: () => ({
		loading: false,
		dataUrl: '/api/',
		items: [],
		options: {},
		//		'no-data-text': '',
		//		'items-per-page': 20,
		message: '',
		dialog: false,
		editedIndex: -1,
		dialogDelete: false,
		defaultItem: { id: '00000000-0000-0000-0000-000000000000' },
		editedItem: {},
		rules: {
			required: value => !!value || '必須の項目',
			datetime: value => !Number.isNaN(Date.parse(value)) || '無効な日付'
		}
	}),
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},
	methods: {
		async reload(options) {
			this.items = [];
			this.loading = true;
			//const res = Axios
			//console.debug(options);
			if (options == null && this.mode != null) {
				options = { params: { mode: this.mode ?? 'public', cat: this.cat ?? 'none', range: this.range ?? '' } }
			}
			Axios
				.get(this.dataUrl, options)
				.then(response => {
					this.items = response.data;
				})
				.catch(error => {
					if (error.response.status == 401) {
						// 認証エラー（セッションが切れている）
						this.sessionout()
					} else if (error.response.status == 0) {
						alert('エラーのためデータ取得できません\nサーバに接続に接続できません');
						//サーバーに接続できない
					} else {
						//不明なエラー
						alert(`エラーのためデータ取得できません\n${error.code}`);
						console.error('エラーのためデータ取得できません');
						console.error(error);
					}
				})
				.finally(() => this.loading = false);
		},
		sessionout() {
			//console.debug("sessionout");
			// セッションストレージから削除
			sessionStorage.removeItem('isSignin')
			sessionStorage.removeItem('username')
			sessionStorage.removeItem('display_name')
			this.$router.push('/signin')
		},
		editItem(item) {
			this.editedIndex = this.items.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		deleteItem(item) {
			this.editedIndex = this.items.indexOf(item)
			this.editedItem = Object.assign({}, item)
			confirm('削除しますか') && this.delete();
		},
		close() {
			this.message = '';
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.message = '';
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		save(event) {
			if (this.editedIndex > -1) {
				Object.assign(this.items[this.editedIndex], this.editedItem)
			} else {
				this.items.push(this.editedItem)
			}
			this.close()
		},
		delete(event) {
			this.items.splice(this.editedIndex, 1)
		},
		formatDate(value) {
			// 日付書式 （yyyy-mm-dd） にする 
			let dt = new Date(value);
			if (dt == 'Invalid Date') {
				return ''
			} else {
				return dt.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
			}
		},
		formatDatetime(value) {
			// 日付書式 （yyyy-mm-dd hh:mm） にする 
			let dt = new Date(value);
			if (dt == 'Invalid Date') {
				return ''
			} else {
				if (dt.getHours() == 0 && dt.getMinutes() == 0) {
					return dt.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
				} else {
					return dt.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
				}
			}
		}
	},
	mounted() {
		this.reload();
	}
}