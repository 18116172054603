<template>
	<ApplicationList mode="private" />
</template>

<script>
import ApplicationList from '../components/ApplicationList'
export default {
	name: 'Application',
	components: {
		ApplicationList
	},
}
</script>
