<template>
	<v-container>
		<div>CSVダウンロード</div>
		<div>ダウンロードファイルの1,2行目は変更削除しないでください。3行目以降は並び替えしても問題ありません</div>
		<v-row class="mb-6">
			<v-col class="mb-1" cols="12" md="4" xl="3" v-for="(item, index) in items" :key="index">
				<v-card :loading="item.loading" color="bg_card lighten-1">
					<v-card-title>{{ item.title }}</v-card-title>
					<v-card-text>
						<v-btn @click="download(index)" :disabled="item.loading" color="primary">ダウンロード</v-btn>
						<v-file-input accept="text/csv" v-model="item.file" v-if="item.importable"></v-file-input>
						<v-btn @click="upload(index)" :disabled="item.loading" v-if="item.importable" color="secondary">アップロード</v-btn>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar v-model="snackbar" color="error">
			{{ message }}
			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" icon v-bind="attrs" @click="snackbar = false">
					<v-icon dense >mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>登録エラー</v-card-title>
				<v-card-text>
					<v-container>
						<div v-for="(msg, index) in messages" :key="index">{{ msg }}</div>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog = false">閉じる</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Axios from 'axios'
export default {
	data: () => ({
		loading: false,
		dataUrl: '/api/admin',
		items: [
			{ title: 'お知らせ', mode: 'info', loading: false, file: null, importable: true },
			{ title: 'グループアドレス帳', mode: 'addressbook', loading: false, file: null, importable: true },
			{ title: 'リンク', mode: 'link', loading: false, file: null, importable: true },
			{ title: '社員マスタ', mode: 'user', loading: false, file: null, importable: true },
			{ title: '承認マスタ', mode: 'workflow', loading: false, file: null, importable: true },
			{ title: '設備マスタ', mode: 'resouce', loading: false, file: null, importable: true },
			{ title: '休日マスタ', mode: 'event', loading: false, file: null, importable: true },
			{ title: '申請データ', mode: 'application', loading: false, file: null, importable: false },
		],
		message: '',
		messages: [],
		snackbar: false,
		dialog: false,
	}),
	methods: {
		async download(index) {
			let self = this
			this.items[index]['loading'] = true
			this.message = '';
			await Axios
				.get('/api/admin/file',
					{
						params: {
							mode: this.items[index]['mode']
						},
						responseType: 'blob'
					}).then(response => {
						const disposition = response.headers['content-disposition'] ?? '';
						const matches = disposition.match(/filename=([^;]+);/);
						if (matches) {
							var filename = matches[1];
						}
						this.blobDownload(response.data, filename);
					}).catch(async error => {
						self.message = 'エラー'
						if (/json/.test(error.response.headers['content-type'])) {
							let errorString = JSON.parse(await error.response.data.text());
							self.message = errorString.message
						} else {
							self.message = error.response.statusText;
						}
						this.snackbar = true
					}).finally(() => {
						this.items[index]['loading'] = false
					})
		},
		upload(index) {
			let self = this
			let file = this.items[index]['file'];
			if (file?.name == null) {
					this.message = 'ファイルを指定してください';
					this.snackbar = true
			}else{
				const formData = new FormData();
				formData.append("file", file);
				this.items[index]['loading'] = true
				this.message = '';
				Axios
					.post('/api/admin/file', formData, {
						params: {
							mode: this.items[index]['mode']
						},
						//headers: { "Content-Type": "multipart/form-data" },
					})
					.then((response) => {
						if (response.data.status == 'success') {
							self.message = '更新しました'
							if (response.data.messages.length > 0) {
								self.messages = response.data.messages
								self.dialog = true
							}
						} else {
							self.message = response.data?.message ?? 'エラーのため更新できません'
						}
						self.snackbar = true
					}).catch((error) => {
						console.error(error)
						self.message = error.response?.data?.message ?? 'エラーのため更新できません'
						self.snackbar = true
					}).finally(() => {
						this.items[index]['loading'] = false
					})
			}
		},
		blobDownload: function (blob, filename) {
			//let blob = new Blob( [ data ], { type: res_contentType } );
			const downloadUrl = URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = downloadUrl;
			a.setAttribute('download', filename);
			document.body.appendChild(a);
			a.click();
			a.remove();
		}
	}
}
</script>

<style>
</style> 