<template>
	<div>
		<v-app-bar app dense color="bg_appbar">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-app-bar-title>{{ title }}</v-app-bar-title>
			<v-spacer></v-spacer>
			<span>{{ display_name }}</span>

			<v-menu left bottom transition="slide-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item href="/singchangepassword">
					<v-list-item-icon><v-icon>mdi-lock-question</v-icon></v-list-item-icon>
					<v-list-item-title>パスワード変更
					</v-list-item-title>
					</v-list-item>
					<v-list-item href="/signout">
					<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
					<v-list-item-title>
					サインアウト
					</v-list-item-title>
					</v-list-item>	
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer app v-model="drawer" color="bg_navi">
			<v-list dense nav>
				<v-list-item v-for="(item, i) in displayNavItems" :key="i" :to="item.to">
					<v-list-item-icon>
						<v-icon v-text="item.icon" />
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="item.title" />
					</v-list-item-content>
				</v-list-item>
			</v-list>

		</v-navigation-drawer>
		<v-main>
			<router-view />
		</v-main>
	</div>
</template>

<script>
//import Navigation from './components/Navigation.vue'
export default {
	name: 'App',
	props: ['title'],
	data: () => ({
		display_name: '',
		username: '',
		admin: false,
		drawer: null,
		navItems: [
			{ title: 'トップ', icon: 'mdi-home', to: '/top', public_item: true },
			{ title: 'グループアドレス帳', icon: 'mdi-book-open-page-variant', to: './addressbook', public_item: true },
			{ title: 'リンク', icon: 'mdi-link', to: './link', public_item: true },
			{ title: 'カレンダー', icon: 'mdi-note-check', to: './calendar', public_item: true },
			{ title: 'お知らせ登録', icon: 'mdi-link', to: './info', public_item: true },
			{ title: '社有車・会議室予約', icon: 'mdi-calendar-multiple-check', to: './reservation', public_item: true },
			{ title: '休暇・出張申請', icon: 'mdi-note-edit', to: './application', public_item: true },
			{ title: '休暇・出張承認', icon: 'mdi-note-check', to: './approve', public_item: true },
			{ title: '管理', icon: 'mdi-note-check', to: './admin', public_item: false },
		],
		//
	}),
	computed: {
		displayNavItems: function () {
			return this.navItems.filter(e => { return (e.public_item || this.admin) })
		}
	},
	methods: {

	},
	mounted: function () {
		this.username = sessionStorage.getItem('username')
		if (!this.username) {
			this.$router.push('/signin')
		} else {
			this.display_name = sessionStorage.getItem('display_name')
			this.admin = (sessionStorage.getItem('admin') === 'true')
		}
	},
};
</script>

<style>
tbody tr:nth-of-type(odd) {
	/* 'teal lighten-5' basides on material design color */
	background-color: #e0f2f1;
}

tbody tr:nth-of-type(even) {
	/* 'deep-orange lighten-5' basides on material design color */
	background-color: #fbe9e7;
}
</style>
