<template>
	<InfoList mode="private"/>
</template>

<script>
import InfoList from '../components/InfoList'
export default {
	name: 'Info',

	components: {
		InfoList
	},
}
</script>
