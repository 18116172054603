<template>
	<LinkList />
</template>

<script>
import LinkList from '../components/LinkList'

export default {
	name: 'Link',

	components: {
		LinkList
	},
}
</script>
